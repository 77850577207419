html,
body {
  height: 100%;
}
.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
.logo {
  animation: load 1s, flash 0.7s infinite alternate-reverse ease-in-out;
  animation-delay: 0s, 1s;
}
.brenntag {
  opacity: 0;
  animation: load 1s ease-in-out;
  animation-fill-mode: forwards;
}
.grd1,
.grd2 {
  animation-delay: 0.4s;
}
.grd1 {
  animation: slideInRight 0.4s ease-in-out;
}
.grd2 {
  animation: slideInLeft 0.4s ease-in-out;
}
